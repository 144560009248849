export const state = () => ({
  flag: false,
  confirmText: '',
  title: {
    icon: 'transaction-history',
    text: 'جزئیات واریز تتر',
  },
  body: {
    icon: 'usdt',
    status: 'confirmed',
    irtPrice: 6250000,
    usdtPrice: 10,
    content: [
      {label: 'تعداد', value: '10 تتر', hasCopy: true},
      {label: 'نرخ لحظه', value: '40,000 تومان'},
      {
        label: 'زمان',
        value: '14:22 - 14 آذر 1401',
        image: 'https://api.coinkade.info/images/bank/2022/2022-06-08/1654668606437.png'
      },
    ],
  }
});

/* export const getters = {
  flag: (state) => state.flag,
}; */

export const mutations = {
  SHOW_FACTOR(state) {
    state.flag = true;
  },
  CLOSE_FACTOR(state) {
    state.flag = false;
    state.confirmText = '';
    state.title = {
      icon: '',
      text: '',
    };
    state.body = {
      icon: '',
      status: '',
      irtPrice: 0,
      usdtPrice: 0,
      content: [],
    }
  },
  SET_FACTOR(state, data) {
    state.flag = !state.flag;
    state.confirmText = data.confirmText ? data.confirmText : '';
    state.title = data.title;
    state.body = data.body;
  }
};

export const actions = {
  setFactorDetails({commit}, data) {
    commit("SET_FACTOR", data);
  },
  show({commit}) {
    commit("SHOW_FACTOR");
  },
  close({commit}) {
    commit("CLOSE_FACTOR");
  }
};

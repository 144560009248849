export const state = () => ({
  flag: false,
  type: null,
  message: null,
});

export const getters = {
  flag: (state) => state.flag,
  type: (state) => state.type,
  message: (state) => state.message,
};

export const mutations = {
  SHOW_POPUP(state, data) {
    state.flag = true;
    state.type = data.type;
    state.message = data.message;
  },
  CLOSE_POPUP(state) {
    state.flag = false;
    state.type = null;
    state.message = null;
  }
};

export const actions = {
  error({commit}, message) {
    let data = {
      type: 'error',
      message
    }
    commit("SHOW_POPUP", data);
  },
  success({commit}, message) {
    let data = {
      type: 'success',
      message
    }
    commit("SHOW_POPUP", data);
  },
  close({commit}) {
    commit("CLOSE_POPUP");
  }
};

export default function ({ $axios, store, redirect }) {
  $axios.onRequest((config) => {});

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status);
    if (code === 401) {
      redirect("/auth");
    } else if (error.response.status === 403) {
      store.dispatch("messagePopup/error", error.response.data.message);
    }
  });
}

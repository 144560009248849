export const state = () => ({
  flag: false,
  type: false,
  submitted: false,
});

export const getters = {
  flag: (state) => state.flag,
  type: (state) => state.type,
  submitted: (state) => state.submitted,
};

export const mutations = {
  SHOW_POPUP(state, type) {
    state.flag = true;
    state.type = type;
  },
  CLOSE_POPUP(state) {
    state.flag = false;
    state.type = false;
  },
  TOGGLE_SUBMIT(state) {
    state.submitted = !state.submitted;
  },
};

export const actions = {
  showPopup({ commit }, type) {
    commit("SHOW_POPUP", type);
  },
  closePopup({ commit }) {
    commit("CLOSE_POPUP");
  },
  toggleSubmit({ commit }) {
    commit("TOGGLE_SUBMIT");
  },
};

export const state = () => ({
  flag: false,
  step: 0,
});

export const getters = {
  flag: (state) => state.flag,
  step: (state) => state.step,
};

export const mutations = {
  SHOW_KYC_STATUS(state) {
    state.flag = true;
  },
  CLOSE_KYC_STATUS(state) {
    state.flag = false;
  },
  SET_STEP(state, data) {
    state.step = data;
  },
};

export const actions = {
  setStep({commit}, data) {
    commit("SET_STEP", data);
  },
  showKyc({commit}, data) {
    commit("SET_STEP", data);
  },
  closeKyc({commit}, data) {
    commit("SET_STEP", data);
  },
};

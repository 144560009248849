export const state = () => ({
  type: '',
  amount: 0
});

export const mutations = {
  SET_ORDER(state, data) {
    state.type = data.type;
    state.amount = data.amount;
  },
  SET_ON_LOCAL(state) {
    localStorage.setItem('buy-sell', JSON.stringify({type: state.type, amount: state.amount}));
  },
  LOAD_FROM_LOCAL(state) {
    if (localStorage.getItem('buy-sell')) {
      let buySell = JSON.parse(localStorage.getItem('buy-sell'));
      localStorage.removeItem('buy-sell');
      state.type = buySell.type;
      state.amount = buySell.amount;
    }
  },
  CLEAR_DATA(state) {
    state.type = '';
    state.amount = 0;
    if (localStorage.getItem('buy-sell')) {
      localStorage.removeItem('buy-sell');
    }
  }
};

export const actions = {
  setOrder({commit}, data) {
    commit("SET_ORDER", data);
  },
  setToLocal({commit}) {
    commit("SET_ON_LOCAL");
  },
  loadFromLocal({commit}) {
    commit("LOAD_FROM_LOCAL");
  },
  clearOrder({commit}) {
    commit("CLEAR_DATA");
  }
};

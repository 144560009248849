export const state = () => ({
  loaded: false,
  wallets: [],
});

export const getters = {
  loaded: (state) => state.loaded,
  wallets: (state) => state.wallets,
};

export const mutations = {
  GET_WALLETS(state) {
    if(state.loaded){

    }
  },
  ADD_ACCOUNT(state) {

  },
  REMOVE_ACCOUNT(state) {

  }
};

export const actions = {
  get({commit}) {
    commit("GET_WALLETS");
  },
  add({commit}, data) {
    commit("ADD_ACCOUNT", data);
  },
  remove({commit}, data) {
    commit("REMOVE_ACCOUNT", data);
  },
};

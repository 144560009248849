export const state = () => ({
  wallets: [],
  allWallets: [],
  total: 0,
  loaded: false,
});

export const mutations = {
  SET_WALLETS(state, data) {
    state.wallets = data;
    // state.loaded = true;
  },
  SET_ALL_WALLETS(state, data) {
    state.allWallets = data;
  },
  SET_TOTAL_WALLET(state, data) {
    state.total = data;
  },
  ADD_WALLET(state, walletObject) {
    state.wallets.unshift(walletObject);
    state.allWallets.unshift(walletObject);
  },
  /*REMOVE_ACCOUNT(state) {

  }*/
};

export const actions = {
  async get({commit, dispatch}, data) {
    dispatch("overlay/toggleOverlay", null, {root: true});
    try {
      let resp = await this.$axios.$get(`/v1/wallet?page=${data.page}&limit=${data.limit}&sort=createdAt&order=desc&query=`);
      commit("SET_WALLETS", resp.wallets);
      commit("SET_TOTAL_WALLET", resp.total);
      dispatch("usdtConfig/set", resp.price, {root: true});
    } catch (err) {
      dispatch("messagePopup/error", err.response.data.message, {root: true});
    } finally {
      dispatch("overlay/toggleOverlay", null, {root: true});
    }
  },
  async getAll({commit, dispatch}) {
    dispatch("overlay/toggleOverlay", null, {root: true});
    try {
      let resp = await this.$axios.$get('/v1/wallet?page=1&limit=150&sort=createdAt&order=desc&query=');
      commit("SET_ALL_WALLETS", resp.wallets);
      dispatch("usdtConfig/set", resp.price, {root: true});
    } catch (err) {
      dispatch("messagePopup/error", err.response.data.message, {root: true});
    } finally {
      dispatch("overlay/toggleOverlay", null, {root: true});
    }
  },
  async add({commit, dispatch}, data) {
    dispatch("overlay/toggleOverlay", null, {root: true});
    try {
      let resp = await this.$axios.$post('/v1/wallet', {
        title: data.title,
        address: data.address,
        memo: '',
      });
      commit("ADD_WALLET", resp.wallet);
      dispatch("usdtConfig/set", resp.price, {root: true});
      dispatch("messagePopup/success", "آدرس کیف پول جدید با موفقیت اضافه شد.", {root: true});
    } catch (err) {
      dispatch("messagePopup/error", err.response.data.message, {root: true});
    } finally {
      dispatch("overlay/toggleOverlay", null, {root: true});
    }
  },
  /*remove({commit}, data) {
    commit("REMOVE_ACCOUNT", data);
  },*/
};

import Vue from "vue";
import { ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/fa.json";

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  });
});

// Add a rule.
extend("faAlpha", {
  validate: (value) => /^[\u0600-\u06FF\s]+$/.test(value),
  message: "تنها مجاز به استفاده از حروف فارسی می باشید.",
});

extend("enAlpha", {
  validate: (value) => /^[A-Za-z\s]*$/i.test(value),
  message: "تنها مجاز به استفاده از حروف انگلیسی می باشید.",
});

extend("enBeta", {
  validate: (value) => /^[A-Za-z\s][A-Za-z0-9]*$/i.test(value),
  message: "تنها مجاز به استفاده از کاراکترهای انگلیسی می باشید.",
});

extend("trxWallet", {
  validate: (value) => /^T[A-Za-z1-9]{33}$/.test(value),
  message: "آدرس کیف پول وارد شده اشتباه می باشد.",
});

// Register it globally
Vue.component("ValidationProvider", ValidationProvider);

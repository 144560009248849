export const state = () => ({
  currentPrice: 0,
  lowestPrice: 0,
  highestPrice: 0,
  dailyPercentage: 0,
  priceInLastMonth: [],
});

/*export const getters = {
  flag: (state) => state.flag,
};*/

export const mutations = {
  SET_NEW_CONFIG(state, data) {
    state.currentPrice = data.usdt;
    state.lowestPrice = data.lowPrice;
    state.highestPrice = data.hightPrice;
    state.dailyPercentage = data.percentageDifference;
    state.priceInLastMonth = data.priceInLastMonth;
  },
};

export const actions = {
  set({ commit }, data) {
    commit("SET_NEW_CONFIG", data);
  },
};

export const state = () => ({
  flag: false,
});

export const getters = {
  flag: (state) => state.flag,
};

export const mutations = {
  TOGGLE_OVERLAY(state) {
    state.flag = !state.flag;
  }
};

export const actions = {
  toggleOverlay({commit}) {
    commit("TOGGLE_OVERLAY");
  },
};

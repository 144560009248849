export const state = () => ({
  accounts: [],
  banksList: [],
  loaded: false,
});

export const getters = {
  combine: (state) => {
    let newAccounts = [];
    state.accounts.forEach(account => {
      let bankIndex = state.banksList.findIndex(bank => bank.prefixCardNumber === account.card.substring(0, 6));
      let shebaNumberFormat = account.sheba.split('IR');
      shebaNumberFormat = "IR " + (shebaNumberFormat[1].match(/.{1,6}/g)).join(' ');
      newAccounts.push({
        id: account.id,
        card: (account.card.match(/.{1,4}/g)).join(' '),
        sheba: shebaNumberFormat,
        bank: {
          name: state.banksList[bankIndex].title,
          image: state.banksList[bankIndex].image,
        },
      });
      // bank: this.banksList.find(bank => bank.prefixCardNumber === account.cardNumber.substring(0, 6))
    });
    return newAccounts;
  },
};

export const mutations = {
  SET_BANK_LIST(state, data) {
    state.banksList = data;
  },
  SET_ACCOUNTS(state, data) {
    state.accounts = data;
    state.loaded = true;
  },
  ADD_ACCOUNT(state, accountObject) {
    state.accounts.push(accountObject);
  },
  /*REMOVE_ACCOUNT(state) {

  }*/
};

export const actions = {
  async bankList({commit, dispatch}) {
    dispatch("overlay/toggleOverlay", null, {root: true});
    try {
      let resp = await this.$axios.$get('/v1/bank');
      commit("SET_BANK_LIST", resp);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch("overlay/toggleOverlay", null, {root: true});
    }
  },
  async getAccounts({commit, dispatch}) {
    dispatch("overlay/toggleOverlay", null, {root: true});
    try {
      let resp = await this.$axios.$get('/v1/account?page=1&limit=10&sort=createdAt&order=asc&query=');
      commit("SET_ACCOUNTS", resp.accounts);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch("overlay/toggleOverlay", null, {root: true});
    }
  },
  async add({commit, dispatch}, data) {
    dispatch("overlay/toggleOverlay", null, {root: true});
    try {
      let resp = await this.$axios.$post('/v1/account', {
        card: data.card,
        count: 1,
      });
      commit("ADD_ACCOUNT", resp.account);
      dispatch("messagePopup/success", "حساب بانکی جدید با موفقیت اضافه شد.", {root: true});
    } catch (err) {
      dispatch("messagePopup/error", err.response.data.message, {root: true});
    } finally {
      dispatch("overlay/toggleOverlay", null, {root: true});
    }
  },
  /*remove({commit}, data) {
    commit("REMOVE_ACCOUNT", data);
  },*/
};

export const state = () => ({
  currentLirPrice: 0,
  lowestLirPrice: 0,
  highestLirPrice: 0,
  dailyPercentage: 0,
  priceInLastMonth: [],
});

/*export const getters = {
  flag: (state) => state.flag,
};*/

export const mutations = {
  SET_NEW_CONFIG(state, data) {
    state.currentLirPrice = data.lir;
    state.lowestLirPrice = data.lowLirPrice;
    state.highestLirPrice = data.hightLirPrice;
    state.dailyPercentage = data.percentageDifference;
    state.priceInLastMonth = data.priceInLastMonth;
  },
};

export const actions = {
  set({ commit }, data) {
    commit("SET_NEW_CONFIG", data);
  },
};

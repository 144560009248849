export const state = () => ({
  flag: false,
});

export const getters = {
  flag: (state) => state.flag,
};

export const mutations = {
  // receive data as object
  SHOW(state) {
    state.flag = true;
  },

  HIDE(state) {
    state.flag = false;
  },
};

export const actions = {
  toggleOverlay({ commit }, data) {
    if (data) {
      commit("SHOW", data);
    } else {
      commit("HIDE", data);
    }
  },
};
